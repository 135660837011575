
import './App.css';

function Home() {

  return(
    <div className="Root">
        <div className="Content">

            <div>
                <img src={process.env.PUBLIC_URL + '/img/logo_navbar.png'} className="Navbar" alt='logo'/>
            </div>
            
            <div>
                <div className="row-container">
                    <div className="row-item">
                        <div >O seu consultório no seu celular!</div>
                        <div className="text-title" style={{fontSize: 36, lineHeight: '44px'}}>Que tal gerenciar o seus procedimentos direto do seu celular?!</div>
                        <div>Com o Implant File você tem toda a sua agenda de procedimentos e estoque de materiais no seu celular.</div>

                        <div className="row-container" style={{justifyContent: 'flex-start', marginTop:20}}>
                            <div style={{marginRight: 15}}>
                                <a href="https://play.google.com/store/apps/details?id=com.implantfile.implantfile"> <img src={process.env.PUBLIC_URL + '/img/store_google.png'} alt='google'/></a>
                            </div>
                            <div>
                                <a href="https://apps.apple.com/us/app/implant-file/id1475995426"><img src={process.env.PUBLIC_URL + '/img/store_apple.png'} alt='apple'/></a>
                            </div>
                        </div>
                    </div>
                    <div className="row-image">
                        <img src={process.env.PUBLIC_URL + '/img/intro.png'} style={{width: '100%'}} alt='intro'/>
                    </div>
                </div>

                <div className="text-title" style={{textAlign: 'center', fontSize: 20}}>
                    Junte-se aos dentistas que já usam o Implant File.
                </div>

                <div className="row-container">
                    <div>
                        <img src={process.env.PUBLIC_URL + '/img/abo.jpg'} alt='abo'/>
                    </div>
                    <div>
                          <img src={process.env.PUBLIC_URL + '/img/inovativa.png'} alt='inovativa' />
                    </div>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/img/inova.png'} alt='inova'/>
                    </div>
                </div>
            </div>

            <div>
                <div className="text-title" style={{fontSize: 36, textAlign: 'center', marginTop: '70px'}}>
                    Feito para você, pensando em você!
                </div>

                <div style={{textAlign: 'center', marginTop: '15px'}}>
                    Te ajudamos a organizar a sua agenda de procedimentos e pacientes de forma simples e ágil.
                </div>

                <div className="row-container">
                    <div className="info-box">
                        <div className="info-icon">
                            <img src={process.env.PUBLIC_URL + '/img/icon1.png'} alt='i1'/>
                        </div>
                        <div className="text-title">Você no controle!</div>
                        <div>Tenha controle total sobre a sua agenda de pacientes e procedimentos.</div>
                    </div>
                    
                    <div className="info-box">
                        <div className="info-icon">
                            <img src={process.env.PUBLIC_URL + '/img/icon2.png'} alt='i2'/>
                        </div>
                        <div className="text-title">Organize o seu material!</div>
                        <div>Mantenha o seu material sempre em dias, te avisamos sempre que precisar.</div>
                    </div>

                    <div className="info-box">
                        <div className="info-icon">
                            <img src={process.env.PUBLIC_URL + '/img/icon3.png'} alt='i3'/>
                        </div>
                        <div className="text-title">Marketplace para você!</div>
                        <div>Não se preocupe mais com fornecedores, nós levamos todos até você.</div>
                    </div>
                </div>

            </div>

            <div style={{marginTop: 50, display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                
                <div style={{width: 400}}>
                    <div className="text-title">
                        Baixe o nosso app agora mesmo e seja um profissional credenciado!
                    </div>
                    <div>
                        Ao se cadastrar no Implant File você já começa a organizar a sua agenda de procedimentos e pacientes.
                    </div>
                </div>

                <div style={{marginTop: 50}}>
                    <div className="row-container">
                        <div className="row-item">
                            <div className="text-title">1. Cadaste os seus pacientes!</div>
                            <div>Cadastrar os seus pacientes é muito fácil e você ainda consegue manter eles sempre atualizados dos seus agendamentos.</div>
                        </div>
                        <div className="row-image">
                            <img src={process.env.PUBLIC_URL + '/img/cell1.png'} style={{width: '100%'}} alt='c1'/>
                        </div>
                    </div>

                    <div className="row-container">
                        <div className="row-item">
                            <div className="text-title">2. Agende um procedimento</div>
                            <div>Após cadastrar um paciente, é hora de marcar um novo procedimento. É simples, rápido e, o melhor, no seu celular.</div>
                        </div>
                        <div className="row-image">
                            <img src={process.env.PUBLIC_URL + '/img/cell2.png'} style={{width: '100%'}} alt='c2'/>
                        </div>
                    </div>

                    <div className="row-container">
                        <div className="row-item">
                            <div className="text-title">3. Mantenha o seu paciente sempre atualizado</div>
                            <div>Após marcar o procedimento do seu paciente, é hora de manter ele avisado. Basta clicar em um botão e ele receberá um SMS.</div>
                        </div>
                        <div className="row-image">
                            <img src={process.env.PUBLIC_URL + '/img/cell3.png'} style={{width: '100%'}} alt='c3'/>
                        </div>
                    </div>

                </div>
            </div>

            <div className="text-title" style={{fontSize: 26, textAlign: 'center'}}>
                    Equipe Responsável
            </div>

            <div className="row-container">
                <div className="info-box">
                    <div className="info-icon">
                        <img src={process.env.PUBLIC_URL + '/img/marcos.jpg'} style={{width: 225}} alt='marcos'/>
                    </div>
                </div>
            </div>

            <div className="row-container">
                
                <div className="info-box">
                    <div className="info-icon">
                        <img src={process.env.PUBLIC_URL + '/img/luis.jpg'} style={{width: 225}} alt='luis'/>
                    </div>
                </div>

                <div className="info-box">
                    <div className="info-icon">
                        <img src={process.env.PUBLIC_URL + '/img/paulo.jpg'} style={{width: 225}} alt='paulo'/>
                    </div>
                </div>

                  {/* <div className="info-box">
                    <div className="info-icon">
                        <img src={process.env.PUBLIC_URL + '/img/leandro.jpg'} style={{width: 225}} alt='leandro'/>
                    </div>
                </div>

                <div className="info-box">
                    <div className="info-icon">
                        <img src={process.env.PUBLIC_URL + '/img/airton.jpg'} style={{width: 225}} alt='airton'/>
                    </div>
                </div> */}

            </div>

            <div style={{display: 'flex', flexDirection:'row', width:500, alignSelf: 'center', marginTop: '50px'}}>
                <a href="https://www.instagram.com/implantfile/"><img src={process.env.PUBLIC_URL + '/img/instagram.png'} style={{width: "100%"}} alt='instagram'/></a>
                <a href="mailto:implantfile@hotmail.com"><img src={process.env.PUBLIC_URL + '/img/mail.png'} style={{width: "100%"}} alt='mail'/></a>
            </div>
                
            <div className="Footer">
                Todos os direitos reservados. Implant File 2020.
            </div>
        </div>
    </div>
  );
}

export default Home;
