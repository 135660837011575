
import './App.css';

function Remove() {

    return (
        <div className="Root">
            <div className="Content">

                <div>
                    <img src={process.env.PUBLIC_URL + '/img/logo_navbar.png'} style={{ width: 300, height: 40 }} alt='navbar' />
                </div>

                <div>
                    <div className="row-container">
                        <div className="row-item">

                            <div className="text-title" style={{ fontSize: 28, lineHeight: '44px' }}>Solicitação de exclusão de usuário.</div>

                            <div >Caso você deseje solicitar a exclusão completa dos seus dados, preencha o formulário abaixo.</div>

                            <div style={{ marginTop: 15 }}>

                                <div style={{ marginTop: 5 }}>
                                    <label for="fname">Usuário</label>
                                    <input style={{ width: "100%" }} type="text" id="fname" name="fname" placeholder="Seu nome completo..." />
                                </div>

                                <div style={{ marginTop: 5 }}>
                                    <label for="email">Email</label>
                                    <input style={{ width: "100%" }} type="text" id="email" name="email" placeholder="Seu email..." />
                                </div>

                                <div style={{ marginTop: 5 }}>
                                    <label for="cpf">CPF</label>
                                    <input style={{ width: "100%" }} type="text" id="cpf" name="cpf" placeholder="CPF..." />
                                </div>

                                <div style={{ marginTop: 5 }}>
                                    <label for="birthdate">Data de nascimento</label>
                                    <input style={{ width: "100%" }} type="text" id="birthdate" name="birthdate" placeholder="Data de nascimento..." />
                                </div>

                                <input style={{ marginTop: 10 }} type="submit" value="Enviar" />

                                {/* </form> */}
                            </div>
                        </div>
                        <div className="row-image">
                            <img src={process.env.PUBLIC_URL + '/img/contact.png'} style={{ width: '100%' }} alt='contact' />
                        </div>
                    </div>

                </div>

                <div className="Footer">
                    Todos os direitos reservados. Implant File 2020.
                </div>
            </div>
        </div>
    );
}

export default Remove;
