
import './App.css';

function Contact() {

  return(
    <div className="Root">
    <div className="Content">

            <div>
                <img src={process.env.PUBLIC_URL + '/img/logo_navbar.png'} style={{width: 300,height: 40}} alt='navbar'/>
            </div>
            
            <div>
                <div className="row-container">
                    <div className="row-item">
                        <div >O seu consultório no seu celular!</div>
                        <div className="text-title" style={{fontSize: 28, lineHeight: '44px'}}>Entre em contato conosco.</div>
                        
                            
                        <a href="https://www.instagram.com/implantfile/"><img src={process.env.PUBLIC_URL + '/img/instagram.png'} style={{width: "70%"}} alt='instagram'/></a>

                        <a href="mailto:implantfile@hotmail.com"><img src={process.env.PUBLIC_URL + '/img/mail.png'} style={{width: "70%"}} alt='mail'/></a>

                        <a href="tel:+558699999999"><img src={process.env.PUBLIC_URL + '/img/phone.png'} style={{width: "70%"}} alt='phone'/></a>

                        

                        <div >Se você encontrou algum problema ou necessita de suporte técnico, preencha o formulário abaixo.</div>

                        <div  style={{marginTop: 15}}>
                            {/* {% with messages = get_flashed_messages() %}
                                {%- for msg in messages %}
                                <div style="color: brown;">{{ msg }}</div>
                                {% endfor -%}
                            {% endwith %} */}
                            
                            {/* <form action="" method="post"> */}

                                <div style={{marginTop: 5}}>
                                <label for="fname">Nome</label>
                                <input style={{width: "100%"}} type="text" id="fname" name="fname" placeholder="Seu nome completo..."/>
                                </div>
                            
                                <div style={{marginTop: 5}}>
                                    <label for="email">Email</label>
                                    <input style={{width: "100%"}} type="text" id="email" name="email" placeholder="Seu email..."/>
                                </div>

                                <div style={{marginTop: 5}}>
                                <label for="country">País/Região</label>
                                <select id="country" name="country">
                                  <option value="brasil">Brasil</option>
                                  <option value="europa">Europa</option>
                                  <option value="eua">EUA</option>
                                  <option value="outros">Outros</option>
                                </select>
                                </div>

                                <div style={{marginTop: 15, display:'flex',flexDirection:'column'}}>
                                <label for="msg">Mensagem</label>
                                <textarea id="msg" name="msg" placeholder="Escreva aqui sua mensagem..." style={{height: 150}}></textarea>
                                </div>
                            
                                <input style={{marginTop: 10}} type="submit" value="Enviar"/>
                            
                            {/* </form> */}
                        </div>
                    </div>
                    <div className="row-image">
                        <img src={process.env.PUBLIC_URL + '/img/contact.png'} style={{width: '100%'}} alt='contact'/>
                    </div>
                </div>

            </div>

            <div className="Footer">
                Todos os direitos reservados. Implant File 2020.
            </div>
        </div>
    </div>
  );
}

export default Contact;
